<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <v-row class="d-flex align-center">
          <v-col
            cols="12"
            md="6"
          >
            <h2>Hi {{ userData.name }}, Welcome back!</h2>
          </v-col>
          <v-col
            cols="12"
            md="3"
            offset-md="3"
          >
            <!-- filter months -->
            <v-menu
              v-model="isMonthMenuOpen"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :append-icon="icons.mdiCalendar"
                  :value="monthRangeText"
                  readonly
                  dense
                  outlined
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="monthRange"
                type="month"
                color="primary"
                range
              >
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  block
                  @click="monthRangeChange()"
                >
                  {{ t('Set Dates') }}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <v-card>
          <v-card-title class="pb-1">
            <span class="text-h6">Your Policies Issued</span>
          </v-card-title>

          <v-card-text class="text-h6">
            <PoliciesIssuedBreakdown
              v-for="customerType in ['Quoted', 'Non quoted/Broker', 'Corporate', 'Niagamas loyalty', 'Staff rate', '']"
              :key="customerType"
              :customer-type="customerType"
              :summary="policies"
            />
            <v-divider class="divider-width primary mt-2"></v-divider>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card>
          <v-card-title class="pb-1">
            <span class="text-h6">Your Outstanding Invoices</span>
          </v-card-title>

          <v-card-text class="text-h6 text--primary pt-3">
            <span>{{ outstandingInvoices }}</span>
            <v-divider class="divider-width primary mt-2"></v-divider>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card>
          <v-card-title class="pb-1">
            <span class="text-h6">Your Commission</span>
          </v-card-title>

          <v-card-text class="text-h6 text--primary pt-3">
            <span>{{ commission }}</span>
            <v-divider class="divider-width primary mt-2"></v-divider>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiCalendar,
  mdiOpenInNew,
} from '@mdi/js'
import {
  inject,
  ref,
  onMounted,
  computed,
} from '@vue/composition-api'
import PoliciesIssuedBreakdown from '@/components/apps/summaries/PoliciesIssuedBreakdown.vue'

export default {
  components: {
    PoliciesIssuedBreakdown,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const userData = JSON.parse(localStorage.getItem('userData'))

    // Properties
    const [dayNow, monthNow, yearNow] = new Date().toLocaleDateString('en-SG').split('/')
    const doneFetching = ref(false)
    const dateRange = ref([`${yearNow}-${monthNow}-01`, `${yearNow}-${monthNow}-28`])
    const monthRange = ref([`${yearNow}-${monthNow}`])
    const isDateMenuOpen = ref(false)
    const isMonthMenuOpen = ref(false)
    const outstandingInvoices = ref(null)
    const policies = ref({})
    const commission = ref(null)

    // Methods
    const fetchClerkAnalytics = () => {
      doneFetching.value = false
      store
        .dispatch('fetchClerkAnalytics', {
          start_month: monthRange.value[0],
          end_month: monthRange.value[1] ? monthRange.value[1] : monthRange.value[0],
        })
        .then(response => {
          const { data } = response.data
          outstandingInvoices.value = data.invoices
          policies.value = data.policies
          commission.value = data.commission

          doneFetching.value = true
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while generating analytics report. Please refresh!')
        })
    }

    // Computed
    const monthRangeText = computed(() => {
      if (Date.parse(monthRange.value[0]) > Date.parse(monthRange.value[1])) {
        const [start, end] = monthRange.value
        monthRange.value[0] = end
        monthRange.value[1] = start
      }

      const startDateText = monthRange.value[0]
      const showEndDate = dateRange.value[0] !== dateRange.value[1] && monthRange.value[1]
      const endDateText = showEndDate ? ` - ${monthRange.value[1]}` : ''

      return `${startDateText}${endDateText}`
    })
    const monthRangeChange = () => {
      isMonthMenuOpen.value = false
      fetchClerkAnalytics()
    }

    // Mounted
    onMounted(async () => fetchClerkAnalytics())

    return {
      t,
      userData,
      dayNow,
      doneFetching,
      isDateMenuOpen,
      isMonthMenuOpen,
      dateRange,
      monthRange,
      monthRangeText,
      monthRangeChange,
      outstandingInvoices,
      policies,
      commission,
      icons: {
        mdiCalendar,
        mdiOpenInNew,
      },
      components: {
        PoliciesIssuedBreakdown,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  .divider-width {
    border-width: 2px;
  }
</style>
